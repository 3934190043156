<template>
    <nav
        class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-primary-800 text-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-2">

        <div
            class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
            <!-- Toggler -->
            <button
                class="cursor-pointer text-white md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                type="button"
                v-on:click="toggleCollapseShow('bg-primary-800 m-2 py-3 px-6')">
                <font-awesome-icon :icon="['fas', 'bars']"/>
            </button>
            <!-- Brand -->
            <router-link
                class="md:block text-center md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-normal p-4 px-0"
                to="/">
                <span class="font-extrabold">PRIME</span>METRICS
            </router-link>
            <!-- Collapse -->
            <div
                class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-2 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
                v-bind:class="collapseShow">

                <!-- Collapse header -->
                <div class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-slate-200">
                    <div class="flex flex-wrap">
                        <div class="w-6/12">
                            <router-link
                                class="md:block text-center md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                                to="/">
                                Prime Metrics
                            </router-link>
                        </div>
                        <div class="w-6/12 flex justify-end">
                            <button
                                type="button"
                                class="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                v-on:click="toggleCollapseShow('hidden')">
                                <font-awesome-icon :icon="['fas', 'times']"/>
                            </button>
                        </div>
                    </div>
                </div>

                <div v-show="user.is_admin">
                    <div>
                        <!-- Divider -->
                        <hr class="my-6 md:flex hidden"/>

                        <!-- Heading -->
                        <h6 class="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Main Navigation
                        </h6>

                        <!-- Navigation -->
                        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
                            <li class="items-center">
                                <router-link
                                    to="/admin/dashboard"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                                                  isActive
                                                    ? 'border rounded border-primary-600 bg-primary-700'
                                                    : 'text-white hover:text-white',
                                                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'tv']"/>
                                        Dashboard
                                    </a>
                                </router-link>
                            </li>
                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-companies').length > 0"
                                class="items-center">
                                <router-link
                                    to="/admin/companies"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'building']"/>
                                        Companies
                                    </a>
                                </router-link>
                            </li>
                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-vehicles').length > 0"
                                class="items-center">
                                <router-link
                                    to="/admin/vehicles"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'truck']"/>
                                        Vehicles
                                    </a>
                                </router-link>
                            </li>
                            <li
                                class="items-center">
                                <router-link
                                    to="/admin/towns"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'city']"/>
                                        Towns
                                    </a>
                                </router-link>
                            </li>
<!--                            <li-->
<!--                                class="items-center">-->
<!--                                <router-link-->
<!--                                    to="/admin/routes"-->
<!--                                    v-slot="{ href, navigate, isActive }"-->
<!--                                >-->
<!--                                    <a-->
<!--                                        :href="href"-->
<!--                                        @click="navigate"-->
<!--                                        class="text-sm py-3 font-bold block px-3"-->
<!--                                        :class="[-->
<!--                  isActive-->
<!--                    ? 'border rounded border-primary-600 bg-primary-700'-->
<!--                    : 'text-white hover:text-white',-->
<!--                ]"-->
<!--                                    >-->
<!--                                        <font-awesome-icon class="mr-2 text-sm"-->
<!--                                                           :class="[isActive ? 'opacity-85' : 'text-white']"-->
<!--                                                           :icon="['fas', 'road']"/>-->
<!--                                        Routes-->
<!--                                    </a>-->
<!--                                </router-link>-->
<!--                            </li>-->
                            <li
                                class="items-center">
                                <router-link
                                    to="/admin/commodities"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'box-open']"/>
                                        Commodities
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <!-- Divider -->
                        <hr class="my-4 md:min-w-full"/>

                        <!-- Heading -->
                        <h6 class="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Settings
                        </h6>

                        <!-- Navigation -->
                        <ul class="md:flex-col md:min-w-full flex flex-col list-none">

                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-tyre-settings').length > 0"
                                class="items-center">
                                <router-link
                                    to="/admin/tyre-settings"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                                          isActive
                                            ? 'border rounded border-primary-600 bg-primary-700'
                                            : 'text-white hover:text-white',
                                        ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'gears']"/>
                                        Tyre Settings
                                    </a>
                                </router-link>
                            </li>

                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-users-menu').length > 0"
                                class="items-center">
                                <router-link
                                    to="/admin/users"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                                          isActive
                                            ? 'border rounded border-primary-600 bg-primary-700'
                                            : 'text-white hover:text-white',
                                        ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'users']"/>
                                        Users
                                    </a>
                                </router-link>
                            </li>

                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-roles-menu').length > 0"
                                class="items-center">
                                <router-link
                                    to="/admin/roles"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'user-tag']"/>
                                        Roles
                                    </a>
                                </router-link>
                            </li>
                            <li
                                class="items-center">
                                <router-link
                                    to="/admin/permissions"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'tags']"/>
                                        Permissions
                                    </a>
                                </router-link>
                            </li>
                            <li
                                class="items-center">
                                <router-link
                                    to="/admin/app-settings"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'mobile']"/>
                                        App Settings
                                    </a>
                                </router-link>
                            </li>
                        </ul>

                    </div>
                </div>

                <div v-show="!user.is_admin">

                    <div>
                        <!-- Divider -->
                        <hr class="my-6 md:flex hidden"/>

                        <!-- Heading -->
                        <h6 class="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Main Navigation
                        </h6>

                        <!-- Navigation -->
                        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
                            <li class="items-center">
                                <router-link
                                    to="/dashboard"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'tv']"/>
                                        Dashboard
                                    </a>
                                </router-link>
                            </li>


                        </ul>
                    </div>

                    <div>
                        <!-- Divider -->
                        <hr class="my-4 md:min-w-full"/>

                        <!-- Heading -->
                        <h6 class="md:min-w-full text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Settings
                        </h6>

                        <!-- Navigation -->
                        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
                            <div class="flex flex-col w-full mb-2">
                                <div
                                    class="items-center duration-300 cursor-pointer"
                                    @click="dropdownTyre()">
                                    <div class="flex justify-between w-full items-center">
                                        <div class="flex flex-row items-center w-full text-sm py-3 font-bold px-3 text-white">
                                            <font-awesome-icon class="mr-2 text-sm text-white"
                                                               :icon="['fas', 'circle-notch']"/>
                                            Tyres
                                        </div>
                                        <span class="text-sm" :class="{ 'hidden': activeDropdown === 'tyres' }">
                                          <font-awesome-icon class="mr-5 text-sm text-white"
                                                             :icon="['fas', 'chevron-down']"/>
                                        </span>
                                        <span class="text-sm " :class="{ 'hidden': activeDropdown !== 'tyres' }">
                                            <font-awesome-icon class="mr-5 text-sm text-white"
                                                               :icon="['fas', 'chevron-up']"/>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-col items-start text-sm mt-2 text-gray-800 font-bold ml-5 border-l border-primary-600 pl-2"
                                    :class="{ 'hidden': activeDropdown !== 'tyres'}">
                                    <li v-show="permissions.length && permissions.filter(i => i.name === 'view-company-tyres').length > 0"
                                        class="items-center w-full">
                                        <router-link
                                            to="/tyres"
                                            v-slot="{ href, navigate, isActive }"
                                        >
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[
                                                  isActive
                                                    ? 'border rounded border-primary-600 bg-primary-700'
                                                    : 'text-white hover:text-white',
                                                ]"
                                            >
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'circle-notch']"/>
                                                Tyres
                                            </a>
                                        </router-link>
                                    </li>

                                    <li
                                        class="items-center w-full">
                                        <router-link
                                            to="/tyre-settings"
                                            v-slot="{ href, navigate, isActive }"
                                        >
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[
                                                  isActive
                                                    ? 'border rounded border-primary-600 bg-primary-700'
                                                    : 'text-white hover:text-white',
                                                ]"
                                            >
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'gears']"/>
                                                Tyre Settings
                                            </a>
                                        </router-link>
                                    </li>
                                    <li
                                        class="items-center w-full">
                                        <router-link
                                            to="/tyre-history"
                                            v-slot="{ href, navigate, isActive }"
                                        >
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[
                                                  isActive
                                                    ? 'border rounded border-primary-600 bg-primary-700'
                                                    : 'text-white hover:text-white',
                                                ]"
                                            >
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'timeline']"/>
                                                Tyre History
                                            </a>
                                        </router-link>
                                    </li>

                                </div>
                            </div>
                            <div class="flex flex-col w-full mb-2">
                                <div
                                    class="items-center duration-300 cursor-pointer"
                                    @click="dropdownInspections()">
                                    <div class="flex justify-between w-full items-center">
                                        <div class="flex flex-row items-center w-full text-sm py-3 font-bold px-3 text-white">
                                            <font-awesome-icon class="mr-2 text-sm text-white"
                                                               :icon="['fas', 'clipboard-list']"/>
                                            Inspections
                                        </div>
                                        <span class="text-sm" :class="{ 'hidden': activeDropdown === 'inspections' }">
                                            <font-awesome-icon class="mr-5 text-sm text-white"
                                                               :icon="['fas', 'chevron-down']"/>
                                        </span>
                                        <span class="text-sm" :class="{ 'hidden': activeDropdown !== 'inspections' }">
                                             <font-awesome-icon class="mr-5 text-sm text-white"
                                                                :icon="['fas', 'chevron-up']"/>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-col items-start text-sm mt-2 text-gray-800 font-bold ml-5 border-l border-primary-600 pl-2"
                                    :class="{ 'hidden': activeDropdown !== 'inspections' }">
                                    <li class="items-center w-full">
                                        <router-link
                                            to="/inspections"
                                            v-slot="{ href, navigate, isActive }">
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[isActive ? 'border rounded border-primary-600 bg-primary-700'  : 'text-white hover:text-white']">
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'clipboard']"/>
                                                Inspections
                                            </a>
                                        </router-link>
                                    </li>

                                    <li
                                        class="items-center w-full">
                                        <router-link
                                            to="/inspection-history"
                                            v-slot="{ href, navigate, isActive }">
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[
                                                          isActive
                                                            ? 'border rounded border-primary-600 bg-primary-700'
                                                            : 'text-white hover:text-white',
                                                        ]">
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'clipboard-check']"/>
                                                Inspection History
                                            </a>
                                        </router-link>
                                    </li>
                                    <li
                                        class="items-center w-full">
                                        <router-link
                                            to="/defects"
                                            v-slot="{ href, navigate, isActive }">
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[
                                                          isActive
                                                            ? 'border rounded border-primary-600 bg-primary-700'
                                                            : 'text-white hover:text-white',
                                                        ]">
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'clipboard-question']"/>
                                                Defects
                                            </a>
                                        </router-link>
                                    </li>
                                    <li
                                        class="items-center w-full">
                                        <router-link
                                            to="/sms-users"
                                            v-slot="{ href, navigate, isActive }">
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[
                                                          isActive
                                                            ? 'border rounded border-primary-600 bg-primary-700'
                                                            : 'text-white hover:text-white',
                                                        ]">
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'users']"/>
                                                Sms Users
                                            </a>
                                        </router-link>
                                    </li>

                                </div>
                            </div>
                            <div class="flex flex-col w-full mb-2">
                                <div
                                    class="items-center duration-300 cursor-pointer"
                                    @click="dropdownJourneys()">
                                    <div class="flex justify-between w-full items-center">
                                        <div class="flex flex-row items-center w-full text-sm py-3 font-bold px-3 text-white">
                                            <font-awesome-icon class="mr-2 text-sm text-white"
                                                               :icon="['fas', 'road-circle-check']"/>
                                            Journey Management
                                        </div>
                                        <span class="text-sm" :class="{ 'hidden': activeDropdown === 'journeys' }">
                                            <font-awesome-icon class="mr-5 text-sm text-white"
                                                               :icon="['fas', 'chevron-down']"/>
                                        </span>
                                        <span class="text-sm" :class="{ 'hidden': activeDropdown !== 'journeys' }">
                                             <font-awesome-icon class="mr-5 text-sm text-white"
                                                                :icon="['fas', 'chevron-up']"/>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-col items-start text-sm mt-2 text-gray-800 font-bold ml-5 border-l border-primary-600 pl-2"
                                    :class="{ 'hidden': activeDropdown !== 'journeys' }">
                                    <li class="items-center w-full">
                                        <router-link
                                            to="/journeys"
                                            v-slot="{ href, navigate, isActive }">
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[isActive ? 'border rounded border-primary-600 bg-primary-700'  : 'text-white hover:text-white']">
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'clipboard-list']"/>
                                                Journeys
                                            </a>
                                        </router-link>
                                    </li>
                                </div>
                                <div
                                    class="flex flex-col items-start text-sm mt-2 text-gray-800 font-bold ml-5 border-l border-primary-600 pl-2"
                                    :class="{ 'hidden': activeDropdown !== 'journeys' }">
                                    <li class="items-center w-full">
                                        <router-link
                                            to="/journey-hops"
                                            v-slot="{ href, navigate, isActive }">
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[isActive ? 'border rounded border-primary-600 bg-primary-700'  : 'text-white hover:text-white']">
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'clipboard-list']"/>
                                                Journey Hops
                                            </a>
                                        </router-link>
                                    </li>
                                </div>
                            </div>
                            <div class="flex flex-col w-full mb-2">
                                <div
                                    class="items-center duration-300 cursor-pointer"
                                    @click="dropdownIncentives()">
                                    <div class="flex justify-between w-full items-center">
                                        <div class="flex flex-row items-center w-full text-sm py-3 font-bold px-3 text-white">
                                            <font-awesome-icon class="mr-2 text-sm text-white"
                                                               :icon="['fas', 'award']"/>
                                            Incentives
                                        </div>
                                        <span class="text-sm" :class="{ 'hidden': activeDropdown === 'incentives' }">
                                            <font-awesome-icon class="mr-5 text-sm text-white"
                                                               :icon="['fas', 'chevron-down']"/>
                                        </span>
                                        <span class="text-sm" :class="{ 'hidden': activeDropdown !== 'incentives' }">
                                             <font-awesome-icon class="mr-5 text-sm text-white"
                                                                :icon="['fas', 'chevron-up']"/>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-col items-start text-sm mt-2 text-gray-800 font-bold ml-5 border-l border-primary-600 pl-2"
                                    :class="{ 'hidden': activeDropdown !== 'incentives' }">
                                    <li class="items-center w-full">
                                        <router-link
                                            to="/incentives"
                                            v-slot="{ href, navigate, isActive }">
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[isActive ? 'border rounded border-primary-600 bg-primary-700'  : 'text-white hover:text-white']">
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'medal']"/>
                                                Incentives
                                            </a>
                                        </router-link>
                                    </li>
                                    <li class="items-center w-full">
                                        <router-link
                                            to="/user-incentives"
                                            v-slot="{ href, navigate, isActive }">
                                            <a
                                                :href="href"
                                                @click="navigate"
                                                class="w-full text-sm py-3 font-bold block px-3 text-white"
                                                :class="[isActive ? 'border rounded border-primary-600 bg-primary-700'  : 'text-white hover:text-white']">
                                                <font-awesome-icon class="mr-2 text-sm"
                                                                   :class="[isActive ? 'opacity-85' : 'text-white']"
                                                                   :icon="['fas', 'id-badge']"/>
                                                User Incentives
                                            </a>
                                        </router-link>
                                    </li>
                                </div>
                            </div>
                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-company-towns').length > 0"
                                class="items-center">
                                <router-link
                                    to="/towns"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'city']"/>
                                        Towns
                                    </a>
                                </router-link>
                            </li>
                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-company-commodities').length > 0"
                                class="items-center">
                                <router-link
                                    to="/commodities"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'box-open']"/>
                                        Commodities
                                    </a>
                                </router-link>
                            </li>
                            <li
                                class="items-center">
                                <router-link
                                    to="/manufacturers"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'industry']"/>
                                        Manufacturers
                                    </a>
                                </router-link>
                            </li>
                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-company-vehicles').length > 0"
                                class="items-center">
                                <router-link
                                    to="/vehicles"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'truck']"/>
                                        Vehicles
                                    </a>
                                </router-link>
                            </li>
                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-company-routes').length > 0"
                                class="items-center">
                                <router-link
                                    to="/routes"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'road']"/>
                                        Routes
                                    </a>
                                </router-link>
                            </li>

                            <li
                                class="items-center">
                                <router-link
                                    to="/branches"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'code-branch']"/>
                                        Branches
                                    </a>
                                </router-link>
                            </li>

                            <li
                                class="items-center">
                                <router-link
                                    to="/customers"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'people-roof']"/>
                                        Customers
                                    </a>
                                </router-link>
                            </li>

                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-company-users-menu').length > 0"
                                class="items-center">
                                <router-link
                                    to="/users"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'users']"/>
                                        Users
                                    </a>
                                </router-link>
                            </li>

                            <li v-show="permissions.length && permissions.filter(i => i.name === 'view-company-roles-menu').length > 0"
                                class="items-center">
                                <router-link
                                    to="/roles"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'user-tag']"/>
                                        Roles
                                    </a>
                                </router-link>
                            </li>
                            <li
                                class="items-center">
                                <router-link
                                    to="/configs"
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <a
                                        :href="href"
                                        @click="navigate"
                                        class="text-sm py-3 font-bold block px-3"
                                        :class="[
                  isActive
                    ? 'border rounded border-primary-600 bg-primary-700'
                    : 'text-white hover:text-white',
                ]"
                                    >
                                        <font-awesome-icon class="mr-2 text-sm"
                                                           :class="[isActive ? 'opacity-85' : 'text-white']"
                                                           :icon="['fas', 'user-tag']"/>
                                        Configs
                                    </a>
                                </router-link>
                            </li>
                        </ul>

                    </div>

                </div>

            </div>
        </div>
    </nav>
</template>
); }

<script>
export default {
    data() {
        return {
            collapseShow: "hidden",
            permissions: [],
            user: {},
            activeDropdown: null,
        };
    },
    mounted() {
        this.getPermissions();
        this.getUser()
    },
    methods: {
        toggleCollapseShow: function (classes) {
            this.collapseShow = classes;
        },
        getPermissions() {
            this.permissions = JSON.parse(localStorage.getItem('permissions'));
        },
        getUser() {
            this.user = JSON.parse(localStorage.getItem('user'));
        },
        dropdownTyre() {
            if (this.activeDropdown === 'tyres') {
                this.activeDropdown = null; // Close if already open
            } else {
                this.activeDropdown = 'tyres';
            }
        },
        dropdownInspections() {
            if (this.activeDropdown === 'inspections') {
                this.activeDropdown = null;
            } else {
                this.activeDropdown = 'inspections';
            }
        },
        dropdownJourneys() {
            if (this.activeDropdown === 'journeys') {
                this.activeDropdown = null;
            } else {
                this.activeDropdown = 'journeys';
            }
        },
        dropdownIncentives() {
            if (this.activeDropdown === 'incentives') {
                this.activeDropdown = null;
            } else {
                this.activeDropdown = 'incentives';
            }
        }
    },
};
</script>
